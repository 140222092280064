<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-start items-center">
     <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
       <i class="fa fa-chevron-left"></i>
     </span>
      <h3>{{ $t('following.production_plan')}}</h3>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3" v-if="item && type">
            <div class="d-flex mx-0 items-start">
              <div class="w-75px h-75px border-15px">
                <img :src="item.order_expense.order.image_link" alt=""
                     class="w-75px h-75px border-15px object-cover primary-border-2px">
              </div>
              <div class="flex-grow-1 row mx-0 mx-2">
                <div class="row w-100 py-3 mx-0 text-lg">
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.instruction_date') }} : </span>
                    <span class=""> {{ item.date }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.dispatch_location') }} : </span>
                    <span class=""> {{ item.dispatch_locations.length > 0 ? item.dispatch_locations.join(',') : '-' }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.order_number') }} : </span>
                    <span class=""> {{ item.order_expense.order.order_number }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.instruction_type') }} : </span>
                    <span class=""> {{ item.order_expense.expense.translations[0].name }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.start_date') }} : </span>
                    <span class=""> {{ item.start_date | laravelDateToSystem }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.end_date') }} : </span>
                    <span class=""> {{ item.end_date | laravelDateToSystem }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 row mx-0" v-if="type === 1">
              <div class="col-12 text-center text-3xl font-weight-boldest mb-3">{{ $t('following.production_plan').toUpperCase() }} {{item.order_size_and_color?'/ '+item.order_size_and_color.full_name:''}}
              </div>
              <div class="col-12 row mx-0 navy-blue-border-1px p-3 border-15px">
                <div class="col-12 d-flex navy-blue-border-bottom-1px">
                  <div class="w-200px h-35px align-content-center text-lg">{{ $t('following.order_amount') }}</div>
                  <div class="flex-grow-1 h-35px text-2xl text-primary text-center font-weight-boldest ">
                    {{ orderAmount }}
                  </div>
                  <span v-if="false" class="w-200px cursor-pointer d-flex justify-content-end" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-30px h-30px object-fill d-inline-block'
                })"></span>
                </div>
                <div class="col-12 d-flex pt-2">
                  <div class="w-200px h-35px align-content-center text-lg">{{ $t('following.actual') }}</div>
                  <div class="flex-grow-1 h-35px text-2xl text-center font-weight-boldest ">
                    {{ actualAmount }}
                  </div>
                  <div class="w-200px"></div>
                </div>
              </div>
            </div>

            <div class="mt-2 row mx-0" v-if="type === 2">
              <div class="col-12 text-center text-3xl font-weight-boldest mb-3">{{ $t('following.production_plan').toUpperCase() }} {{item.order_size_and_color?'/ '+item.order_size_and_color.full_name:''}}
              </div>
              <div class="col-12 row mx-0 navy-blue-border-1px p-3 border-15px">
                <div
                    class="col-12 d-flex justify-content-center items-center font-weight-boldest navy-blue-border-bottom-1px pb-3">
                  <span class="text-2xl text-primary d-inline-block px-3">{{ $t('following.order_amount') }}</span>
                  <span class="text-3xl text-primary d-inline-block px-3">{{ orderAmount }}</span>
                  <span class="text-3xl d-inline-block px-3">/</span>
                  <span class="text-3xl d-inline-block px-3">{{ actualAmount }}</span>
                  <span class="text-2xl d-inline-block px-3">{{ $t('following.actual') }}</span>
                </div>
                <div class="col-12 table-responsive px-0" style="overflow-scrolling: auto;">
                  <table class="table backgrid">
                    <thead>
                    <tr>
                      <th colspan="2"
                          class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                          :class="{'navy-blue-border-left-1px': index === 0} "
                          v-for="(target, index) in item.order_expense_detail_targets">
                        <div class="d-flex justify-content-between items-center ">
                          <p class="text-center text-xl font-weight-bold mb-0 flex-grow-1">
                            <template v-if="Number(target.id) !== -1">
                              {{ target.date | laravelDateToSystem }}
                            </template>
                            <template v-else>
                                <date-picker-input
                                  :required="false"
                                  :title="null"
                                  :name="`target_date_${index}`"
                                  :model.sync="target.date"
                                ></date-picker-input>
                            </template>
                          </p>
                          <span v-if="false && index === item.order_expense_detail_targets.length - 1 && false"
                                @click="addNewTarget"
                                class="w-35px cursor-pointer float-right" v-html="getIconByKey('icons.waybill.waybill_entry', {
                            class: 'w-30px h-30px object-fill d-inline-block'
                          })">
                          </span>
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="">
                      <template v-for="(target, index) in item.order_expense_detail_targets">
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px"
                            :class="{'navy-blue-border-left-1px': index === 0}"
                        >{{ $t('following.target') }}
                        </td>
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                          {{ $t('following.actual') }}
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <template v-for="(target, index) in item.order_expense_detail_targets">
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px"
                            :class="{'navy-blue-border-left-1px': index === 0}"
                        >
                          <template v-if="Number(target.id) !== -1">
                          {{ target.target }}
                          </template>
                          <template v-else>
                            <div class="row">
                              <div class="col-6">
                                <input type="number" v-model="target.target" step="1" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs">
                              </div>
                              <div v-if="false" class="col-6 py-1 px-1" style="max-width: 100px;">
                                <button-with-icon
                                    @onClick="saveNewTarget(index)"
                                    :disabled="Number(target.target) === 0 && is_submitting"
                                    :text="$t('following.save')"
                                    :icon-name="null"
                                    size="sm"
                                ></button-with-icon>
                              </div>
                            </div>
                          </template>
                        </td>
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px"
                            :class="{'navy-blue-border-left-1px': index === 0}"
                        >{{ target.actual }}
                        </td>
                      </template>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="mt-2 row mx-0">
              <div class="col-12 text-center text-3xl font-weight-boldest">
                {{ $t('following.production_entries').toUpperCase() }}
                <span v-if="false && canAddInput && isUserGranted('Staff', ['supplierCompanyStaff'], false)"
                      @click="addNewInput"
                      class="cursor-pointer float-right" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-30px h-30px object-fill d-inline-block'
                })"></span>
              </div>
              <div class="col-12 row mx-0 navy-blue-border-1px pt-3 px-0 border-15px">
                <div class="col-12 row mx-0 navy-blue-border-bottom-1px">
                  <div class="col-6 text-lg font-weight-boldest py-2 text-center navy-blue-border-right-1px">{{ $t('following.date') }}</div>
                  <div class="col-6 text-lg font-weight-boldest py-2 text-center">{{ $t('following.actual') }}</div>
                </div>
                <div class="col-12 row mx-0" v-for="(input, index) in targetInputs"
                  :class="{'navy-blue-border-bottom-1px': index !== item.order_expense_detail_targets.length - 1}"
                >
                  <div class="col-6 text-lg text-center py-1 navy-blue-border-right-1px">{{ input.date }}</div>
                  <div class="col-6 text-lg text-center py-1 d-flex justify-content-center">
                    <template v-if="canCheckInsertScope||((Number(input.id) === -1 || isSameDate(input.date)) && canAddInput && !item.order_expense.expense.suppliers_can_enter_body_sizes)">
                      <div class="row w-200px">
                        <div class="col-8">
                          <input type="number" step="1" v-model="input.amount" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-sm">
                        </div>
                        <div class="col-4 px-1 py-1">
                          <button-with-icon v-if="!item.order_expense.expense.suppliers_can_enter_body_sizes"
                              @onClick="saveOrUpdateInput(index)"
                            :text="Number(input.id) === -1 ? $t('following.save') : $t('following.update')"
                            :icon-name="null"
                            size="sm"
                            :disabled="Number(input.amount) === 0 && is_submitting"
                          ></button-with-icon>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      {{ input.amount }}
                    </template>
                  </div>
                </div>
              </div>
            </div>

            
            <div class="mt-2 row mx-0" v-if="item.order_expense.expense.suppliers_can_enter_body_sizes">
              <div class="col-12 text-center text-3xl font-weight-boldest">
                {{ $t('following.production_entries_by_size').toUpperCase() }}
              </div>
              <table class="table backgrid navy-blue-border-1px pt-3 px-0 border-15px">
                    <thead class="">
                    <tr>
                      <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        {{ $t('following.date') }}
                      </th>
                      <th
                          class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                          v-for="(sizes, index) in bodySizes" :key="index">
                        <div class="d-flex justify-content-between items-center ">
                          {{ sizes.name }}
                        </div>
                      </th>
                      <th lass="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        #
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(target, index) in  targetInputs" :key="index">
                        <td 
                         class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                         v-if="Number(target.id) !== -1">
                          {{ target.date | laravelDateToSystem }}
                        </td>
                        <td
                          class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                          v-for="(sizes, index) in target.actual_by_sizes" :key="index">
                          <input v-if="canCheckInsertScope||((Number(target.id) === -1 || isSameDate(target.date)) && canAddInput)" 
                          type="number" step="1" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" v-model="sizes.actual">
                          <template v-else>
                            {{ sizes.actual }}
                          </template>
                        </td>
                        <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                          <button-with-icon
                            v-if="canCheckInsertScope||((Number(target.id) === -1 || isSameDate(target.date)) && canAddInput && item.order_expense.expense.suppliers_can_enter_body_sizes)"
                              @onClick="saveOrUpdateInput(index)"
                            :text="Number(target.id) === -1 ? $t('following.save') : $t('following.update')"
                            :icon-name="null"
                            size="sm"
                            :disabled="Number(target.amount) === 0 && is_submitting"
                          ></button-with-icon>
                        </td>
                      
                      </tr>
                    </tbody> 
                  </table>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import {CREATE_ITEM, GET_ITEM_DETAIL_BY_ID, LOADING, UPDATE_ITEM_BY_ID} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import {GET_ITEMS} from "@/core/services/store/modelhouse/modelhouse.module";

export default {
  name: "EditFollowingProduction",
  components: {
    DatePickerInput,
    ButtonWithIcon,
    DashboardBox
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    canCheckInsertScope(){
      if(this.isUserGranted('FollowingProduct', ['updateActual'],false,true)){
        return true;
      }else{
        return false;
      }
      
    },
    canAddInput(){
      for(let i = 0; i < this.item.order_expense_detail_targets.length; i++){
        const target = this.item.order_expense_detail_targets[i];
        if(moment(target.date, LARAVEL_DATE_FORMAT).isSame(moment(), 'day') && Number(target.id) !== -1){
          return true;
        }
      }
      return false;
    },
    bodySizes(){
      let body_sizes=JSON.parse(this.item.order_size_and_color.body_sizes);
      return body_sizes;
    },
    orderAmount(){
      if(this.item == null) return;
      let total = 0;
      this.item.order_expense_detail_targets.forEach(target => {
        total += Number(target.target);
      })
      return total;

    },
    bodySizesTargets(){
      let dateBodySizesTable
    },
    actualAmount(){
      if(this.item == null) return;
      let total = 0;
      this.item.order_expense_detail_targets.forEach(target => {
        total += Number(target.actual);
      })
      return total;
    }
  },
  data() {
    return {
      type: null,
      id: Number(this.$route.params.id),
      item: null,
      body_and_sizes_targets:[],
      is_submitting: false,
      targetInputs:[]
    };
  },
  methods: {
    ...mapMutations({}),
    saveOrUpdateInput(index){
      if (!this.isUserGranted('Staff', ['supplierCompanyStaff', 'superAdmin'])) {
        this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
        return;
      }

      if(this.is_submitting) return;
      this.is_submitting = true;
      this.sweetAlertLoading();
      const current = this.targetInputs[index], self = this;
      let actualBySizes=this.item.order_expense.expense.suppliers_can_enter_body_sizes?current.actual_by_sizes:null;

      this.$store.dispatch(Number(current.id) === -1 ? CREATE_ITEM : UPDATE_ITEM_BY_ID, {
        url: Number(current.id) === -1 ? `api/orders/${this.item.order_expense.order_id}/expenses/${this.item.order_expense.id}/details/${this.id}/production-input` :
            `api/orders/${this.item.order_expense.order_id}/expenses/${this.item.order_expense.id}/details/${this.id}/production-input/${current.id}`,
        contents: {
          date: moment().format(LARAVEL_DATE_FORMAT),
          amount: current.amount,
          order_expense_detail_id: this.id,
          actual_by_sizes:actualBySizes
        },
        showLoading: false,
      }).then(response => {
        self.sweetAlertClose();
        if(response.status){
          self.sweetAlertSuccess(self.$t('general.successfully_saved')).then(() => {
            self.followingItem()
          });

          self.targetInputs[index].id = response.data.id;

        }else{
          self.sweetAlertError(self.$t('general.internal_error'));
        }
        self.is_submitting = false;
      })
    },
    isSameDate(date){
      return moment().isSame(moment(date, LARAVEL_DATE_FORMAT), 'day');
    },
    addNewInput(){
      if (this.isUserGranted('Staff', ['supplierCompanyStaff'])) {
        this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
        return;
      }

      if(this.item == null) return;
      this.item.order_expense_detail_targets.push({
        id: -1,
        amount: null,
        date: moment().format(LARAVEL_DATE_FORMAT),
      })
    },
    saveNewTarget(index){
      this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
      return;

      if(this.is_submitting) return;
      this.is_submitting = true;
      this.sweetAlertLoading();
      const self = this;
      this.$store.dispatch(CREATE_ITEM, {
        url: `api/orders/${this.item.order_expense.order_id}/expenses/${this.item.order_expense.id}/details/${this.id}/targets`,
        contents: {
          date: this.item.order_expense_detail_targets[index].date.format(LARAVEL_DATE_FORMAT),
          target: this.item.order_expense_detail_targets[index].target
        },
        showLoading: false,
      }).then(response => {
        self.sweetAlertClose();
        if(response.status){
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          self.followingItem();
        }else{
          self.sweetAlertError(self.$t('general.internal_error'));
        }
        self.is_submitting = false;
      })
    },
    addNewTarget(){
      this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
      return;

      if(this.item == null) return;
      this.item.order_expense_detail_targets.push({
        "id": -1,
        "order_expense_detail_id": this.id,
        "date": moment(),
        "target": null,
        "deleted_at": null,
        "actual": 0
      })
    },
    followingItem() {
      let self = this;
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: `api/orders/expenses/details/${this.id}`,
        showLoading: true,
      }).then(response => {
        if(response.status){
          self.item = response.data;
          self.type = Number(response.data.order_expense.expense.type_id);
          self.item.dispatch_locations = self.item.order_expense_detail_dispatch_locations.map(location => {
            return location.dispatch_location.name;
          })
          let results = [];
          self.targetInputs=[]
          if (!self.item) return self.targetInputs=results;
          
          let body_sizes=JSON.parse(self.item.order_size_and_color.body_sizes);
          
          

          self.item.order_expense_detail_targets.forEach(item => {
            
            let actualBySizes=[];
            if(item.actual_by_sizes!=null){
              actualBySizes=JSON.parse(item.actual_by_sizes);
            }else{
              Object.entries(body_sizes).forEach(bodySize=>{
                actualBySizes.push(
                  {
                    'body_size_id':bodySize[1].body_size_id,
                    'actual':0,
                    'name':bodySize[1].name,
                    'edit':true
                    
                  }
                )
              })
            }
            let edited=true; //geçmiş kayıtların bu güncellemeden etkilenmeden tabloda girilen adetlerin görünmesi için yapıldı
            if(item.actual>0 && item.actual_by_sizes==null){
              edited=false;
            }
            results.push({
              id: item.id,
              date: item.date,
              amount: item.actual,
              actual_by_sizes:actualBySizes,
              edited:edited
            })
          });
        self.targetInputs= results;
        
        } else{
          self.sweetAlertError(self.$t('general.invalid_request')).then(() => {
            self.$router.go(-1);
          });
        }
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('following.production_plan'), route: 'following_production.index'},
      {title: this.$t('general.dashboard')}
    ]);

    if(this.id === 0) {
      this.$router.go(-1);
      return;
    }

    this.followingItem();
  },
  watch: {
    'targetInputs': {
      handler(newValue, oldValue) {
        if(this.item.order_expense.expense.suppliers_can_enter_body_sizes){
          let totalActual=0;
          newValue.forEach((item,index)=>{
            totalActual=0;
            item.actual_by_sizes.forEach(sizes=>{
              totalActual+=Number(sizes.actual)
            })
            if(item.edited){
              this.targetInputs[index].amount=totalActual;
            }
          })
        }
      },
      immediate: true,
      deep: true
    }
  }
  }

</script>

<style scoped>
.min-w-300px {
  min-width: 300px !important;
}

.min-w-150px {
  min-width: 150px !important;
}
</style>
